.auth-container {
  position: relative;
  width: 100%;
  height: 100%;
  min-height: 100vh;
  padding: 50px 0;
  display: flex;
  align-items: center;
  justify-content: center;
}

.auth-card {
  position: relative;
  width: 100%;
  max-width: 550px;
  height: 100%;
  background: var(--white);
  border-radius: 8px;
  box-shadow: 0 0 10px 0 var(--shadow-black);
  transition: all 0.5s;
}

.auth-card.card-lg {
  max-width: 750px;
}

.auth-card.card-full {
  max-width: 100%;
}

.auth-card .auth-header {
  position: relative;
  width: 100%;
  padding: 20px;
  font-size: 28px;
  font-weight: 700;
  text-align: center;
  border-bottom: 1px solid var(--shadow-black);
}

.auth-card .auth-body {
  padding: 20px;
}

.form-group label {
  display: block;
  margin-bottom: 6px;
}

.form-group input,
.form-group textarea,
.form-group select {
  position: relative;
  width: 100%;
  height: 45px;
  padding: 8px;
  border-radius: 8px;
  border: 1px solid var(--shadow-black-2);
  outline: none;
  background: var(--white);
  transition: all 0.3s;
}

.form-group textarea {
  height: 150px;
}

.form-group .form-control-lg {
  height: 45px !important;
  font-size: 16px !important;
  padding: 10px;
  box-shadow: none !important;
}

.form-group input:focus {
  border-color: var(--shadow-black-3);
}

.form-group .form-password {
  display: flex;
  position: relative;
  width: 100%;
  height: 45px;
  border-radius: 8px;
  border: 1px solid var(--shadow-black-2);
  outline: none;
  background: var(--white);
  transition: all 0.3s;
}

.form-group .form-password input {
  width: 100%;
  height: 100%;
  padding: 8px;
  border: 0;
  background: transparent;
}

.form-group .form-password button {
  position: relative;
  width: 45px;
  height: 45px;
  padding: 5px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  background: transparent;
  border: 0;
  outline: 0;
}

.pagination {
  gap: 2px;
}

.pagination .previous a,
.pagination .active a,
.pagination .next a {
  text-decoration: none;
  color: #fff !important;
  padding: 6px 12px;
  border-radius: 4px;
  background: #212529;
}

.pagination li a {
  padding: 6px 12px;
}

.pagination li.disabled a {
  background: #a9a9a9;
}

.tab-container {
  position: relative;
  width: 100%;
  display: flex;
  align-items: center;
  margin-top: 50px;
}

.tab-container .tab-btn {
  position: relative;
  display: block;
  width: 100%;
  padding: 10px 24px;
  border: 0;
  border-bottom: 1px solid var(--shadow-black-2);
  background: transparent;
  /* border-radius: 8px 8px 0 0; */
}

.tab-container .tab-btn.active {
  border-top: 1px solid var(--shadow-black-2);
  border-left: 1px solid var(--shadow-black-2);
  border-right: 1px solid var(--shadow-black-2);
  border-bottom: 0;
  background: #212529;
  color: #fff;
}

/* EMAIL TABS */
.emailTabs.nav.nav-tabs {
  flex-wrap: nowrap;
  overflow-x: auto;
  overflow-y: hidden;
  white-space: nowrap;
}

.emailTabs.nav.nav-tabs::-webkit-scrollbar {
  height: 1px;
}

.emailTabs.nav.nav-tabs::-webkit-scrollbar-track {
  background: transparent;
}

.emailTabs.nav.nav-tabs::-webkit-scrollbar-thumb {
  background: #a9a9a9;
}

.emailTabs.nav.nav-tabs .nav-item button {
  font-size: 12px;
  padding: 6px 8px;
}
