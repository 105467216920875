* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  scroll-behavior: smooth;
}

:root {
  --snow: #fafdff;
  --white: #fff;
  --shadow-black: rgba(0, 0, 0, 0.05);
  --shadow-black-2: rgba(0, 0, 0, 0.25);
  --shadow-black-3: rgba(0, 0, 0, 0.5);
}

body {
  background: var(--snow);
  font-family: "Karla", sans-serif;
}

.link {
  color: #6495ed;
  transition: all 0.3s;
  cursor: pointer;
  user-select: none;
}

.link:hover {
  color: #427eec;
}

.btn-primary {
  padding: 8px 24px !important;
  border-radius: 8px !important;
  background: #427eec !important;
}

.btn-primary:hover,
.btn-primary:focus {
  background: #3172ec !important;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}

.section {
  position: relative;
  padding: 50px 0;
}

.table {
  white-space: nowrap !important;
}

.loader-container {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 999999;
  background: var(--shadow-black);
  display: flex;
  align-items: center;
  justify-content: center;
}

.loader-container .loader {
  width: 48px;
  height: 48px;
  border-radius: 50%;
  display: inline-block;
  position: relative;
  border: 3px solid;
  border-color: #fff #fff transparent transparent;
  box-sizing: border-box;
  animation: rotation 1s linear infinite;
}

.loader-container .loader::after,
.loader-container .loader::before {
  content: "";
  box-sizing: border-box;
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  margin: auto;
  border: 3px solid;
  border-color: transparent transparent #427eec #427eec;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  box-sizing: border-box;
  animation: rotationBack 0.5s linear infinite;
  transform-origin: center center;
}

.loader-container .loader::before {
  width: 32px;
  height: 32px;
  border-color: #000 #000 transparent transparent;
  animation: rotation 1.5s linear infinite;
}

@keyframes rotation {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
@keyframes rotationBack {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(-360deg);
  }
}

/* TABLE LOADER */
.table-loader {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 50px;
}

.table-loader .loader {
  width: 48px;
  height: 48px;
  display: inline-block;
  position: relative;
}

.table-loader .loader::after,
.table-loader .loader::before {
  content: "";
  box-sizing: border-box;
  width: 48px;
  height: 48px;
  border-radius: 50%;
  border: 2px solid #427eec;
  position: absolute;
  left: 0;
  top: 0;
  animation: animloader 2s linear infinite;
}
.table-loader .loader::after {
  animation-delay: 1s;
}

@keyframes animloader {
  0% {
    transform: scale(0);
    opacity: 1;
  }
  100% {
    transform: scale(1);
    opacity: 0;
  }
}
